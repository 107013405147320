import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import api from "../../api.config";
import Loading from "components/Loading/Loading";

import { userAtom } from "state/auth";

import { useRecoilValue } from "recoil";

import update from 'immutability-helper';
import { TextField } from "@material-ui/core";
import { lastHBABFormAtom } from "state/HBABFormRequest";


const HitByABusFormComponent = (props) => {

  const user = useRecoilValue(userAtom)
  const lastForm = useRecoilValue(lastHBABFormAtom)

  const formats = [
    'bold',
    'color',
    'italic',
    'strike',
    'underline',
    'link',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
  ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: "ordered" }],
      [{
        'color': [
          '#99a3a4', '#7f8c8d', '#707b7c', '#616a6b', '#515a5a', '#424949', '#000000',
          '#e6b0aa', '#d98880', '#cd6155', '#c0392b', '#a93226', '#922b21', '#641e16',
          '#f5cba7', '#f0b27a', '#eb984e', '#e67e22', '#ca6f1e', '#af601a', '#935116',
          '#f7dc6f', '#f4d03f', '#f1c40f', '#d4ac0d', '#b7950b', '#9a7d0a', '#7d6608',
          '#82e0aa', '#58d68d', '#2ecc71', '#28b463', '#239b56', '#1d8348', '#186a3b',
          '#7fb3d5', '#5499c7', '#2980b9', '#2471a3', '#1f618d', '#1a5276', '#154360',
          '#bb8fce', '#a569bd', '#8e44ad', '#7d3c98', '#6c3483', '#5b2c6f', '#4a235a',
        ]
      }],
    ],
  }

  const emptyForm = {
    id: null,
    dateCreated: null,
    name: '',
    email: '',
    ministry: '',
    keyLeaders: {
      html: '',
      content: null
    },
    leaders: {
      html: '',
      content: null
    },
    urgentTasks: {
      html: '',
      content: null
    },
    tasks: {
      html: '',
      content: null
    },
    contacts: {
      html: '',
      content: null
    },
    resources: {
      html: '',
      content: null
    },
    misc: {
      html: '',
      content: null
    }
  }

  const [formInfo, setFormInfo] = useState(lastForm !== null ? lastForm : emptyForm)

  const checkLength = (val) => {
    if (JSON.stringify(val).length > 65000) {
      alert("You've Exceeded the Maximum Length of this Textbox.")
      return true
    }
    return false
  }

  const submitFormHandler = async () => {
    let errors = []
    if (formInfo.ministry.trim() === '') {
      errors.push('Enter the Name of Your Ministry.')
    }

    if (formInfo.keyLeaders.html === '<p><br></p>' || formInfo.keyLeaders.html === '') {
      errors.push("Add a Key Leader (Question 1).")
    }

    if (formInfo.leaders.html === '<p><br></p>' || formInfo.leaders.html === '') {
      errors.push("Add a Leader You Oversee or Type N/A (Question 2).")
    }

    if (formInfo.urgentTasks.html === '<p><br></p>' || formInfo.urgentTasks.html === '') {
      errors.push("Add a Urgent Task (Question 3).")
    }

    if (formInfo.tasks.html === '<p><br></p>' || formInfo.tasks.html === '') {
      errors.push("Add a Less Urgent Task (Question 4).")
    }

    if (formInfo.contacts.html === '<p><br></p>' || formInfo.contacts.html === '') {
      errors.push("Add a Organization You Contact or Type N/A (Question 5).")
    }

    if (formInfo.resources.html === '<p><br></p>' || formInfo.resources.html === '') {
      errors.push("Add Your Means of Communication or Type N/A (Question 6).")
    }

    if (formInfo.keyLeaders.html === '<p><br></p>' || formInfo.keyLeaders.html === '') {
      errors.push("Add Additional Information or Type N/A (Question 7).")
    }

    if (errors.length > 0) {
      let msg = ''
      errors.forEach((error, index) => {
        if (index !== 0) {
          msg += '\n'
        }
        msg += error
      })
      alert(msg)
    } else {
      let data = {
        ministry: formInfo.ministry,
        keyLeaders: JSON.stringify(formInfo.keyLeaders),
        leaders: JSON.stringify(formInfo.leaders),
        urgentTasks: JSON.stringify(formInfo.urgentTasks),
        tasks: JSON.stringify(formInfo.tasks),
        contacts: JSON.stringify(formInfo.contacts),
        resources: JSON.stringify(formInfo.resources),
        misc: JSON.stringify(formInfo.misc),
      }
      await axios
        .post(`${api.path}/staff_portal_api/v1/createHBABForm`,
          data
        )
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      {formInfo.id && (
        <GridItem xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary"
            onClick={() => location.href = `/portal/MyProfile/HBAB/Role/${formInfo.id}?type=Me`}
          >
            View Forms
          </Button>
        </GridItem>
      )}

      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <h4 style={{ fontWeight: 'bold' }}>HBAB</h4>
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <p style={{ fontSize: '18px' }}><strong>Name:</strong> {user.firstName} {user.lastName}</p>
            </GridItem>
            <GridItem xs={12}>
              <p style={{ fontSize: '18px' }}><strong>Email:</strong> {user.email}</p>
            </GridItem>
            <GridItem xs={12}>
              <div style={{ display: ' flex', alignItems: 'center', marginTop: '10px' }}>
                <p style={{ fontSize: '18px', paddingRight: '10px' }}><strong>Ministry:</strong></p>
                <TextField
                  label='My Ministry'
                  variant="outlined"
                  value={formInfo.ministry}
                  onChange={e => {
                    setFormInfo(prevState => {
                      return update(prevState, {
                        ministry: { $set: e.target.value }
                      })
                    })
                  }}
                  fullWidth
                  required
                />
              </div>

            </GridItem>
            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>1.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Who is your most key leader(s) in your ministry who can help to manage your
                  responsibilities?</h4>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                placeholder={'Example:\nJane Doe\n(505)123-4567\njanedoe@sagebrush.church\nSetup & Teardown Wednesdays'}
                value={formInfo.keyLeaders.html}
                defaultValue={formInfo.keyLeaders.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      keyLeaders: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>2.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Who are your mentors or staff persons that you directly oversee? (These are your
                  second-tier leaders)</h4>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.leaders.html}
                placeholder={'Example:\nJon Doe\n(505)123-4567\njondoe@sagebrush.church\nOversees Thursday evening at EH and all group leaders'}
                defaultValue={formInfo.leaders.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      leaders: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>3.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>What are the key weekly responsibilities that MUST be done in order for our
                  ministry to survive? (Please list the person either on staff or on your volunteer
                  team who can cover the responsibility)</h4>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.urgentTasks.html}
                placeholder={'Example:\nWorkflows – Jane Doe\nPost blog and group info on Facebook – Jane Doe\nPrint rosters for Wednesday groups – Jon Doe'}
                defaultValue={formInfo.urgentTasks.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }

                    return update(prevState, {
                      urgentTasks: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>4.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>What are the key weekly responsibilities that need to be covered, but are LESS
                  urgent?</h4>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.tasks.html}
                placeholder={'Example:\nWrite leaders guides for curriculum – Jon Doe\nBirthday cards for group leaders – Jon Doe'}
                defaultValue={formInfo.tasks.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      tasks: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>5.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Key Organizations that you might need to be in contact with in my absence? (We
                  want to know what institutions, counseling centers, youth shelters, community action
                  groups, or other key organization that you contact regularly)</h4>
              </div>
              <div style={{ paddingLeft: '30px' }}>
                <p>
                  Things to Consider: <br />
                  a. What is the organization? <br />
                  b. How are they connected to us?<br />
                  c. Who is the point person?<br />
                  d. What is the best way to contact them?<br />
                  e. How often were you connecting to them?<br />
                  f. Dependencies (what do you do for the specific organization or what are you dependent upon from them)
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.contacts.html}
                placeholder={'Example:\nI primarily work with the different counselors in town. All are on the counselor list on the server under pastoral care.'}
                defaultValue={formInfo.contacts.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      contacts: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>6.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Means of communication…</h4>
              </div>

              <div style={{ paddingLeft: '30px' }}>
                <p>
                  a. Where can we find a current list of your leaders?<br />
                  b. What is the best way to contact your leaders? Email, Text, Phone Calls?<br />
                  c. How do you usually send this?<br />
                  d. Where do you keep your matrix or manage your numbers?<br />
                  e. Who do we need to report your numbers to if needed?<br />
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.resources.html}
                defaultValue={formInfo.resources.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      resources: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>7.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Anything else that we need to know in your absence?</h4>
              </div>

              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.misc.html}
                defaultValue={formInfo.misc.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      misc: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12} style={{ textAlign: 'right' }}>
              <Button color="primary"
                onClick={submitFormHandler}
              >
                Save
              </Button>
            </GridItem>

          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const HitByABusForm = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GridContainer>
        <GridItem xs={12} lg={8}>
          <HitByABusFormComponent {...props} />
        </GridItem>
      </GridContainer>
    </React.Suspense>
  )
}

export default HitByABusForm